<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="require('@/assets/images/logos/dente.png')" max-height="60px" max-width="60px" alt="logo"
              contain class="me-3 "></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Ihealth-Plus
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2 text-center">
            Faça login para ter acesso a agenda da sua empresa
          </p>
        </v-card-text>

        <v-card-text v-if="mensagemErroTrue">
          <v-alert dense outlined type="error">
            {{ this.mensagemErro }}
          </v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text ref="form">
          <v-form>
            <v-text-field v-model="usuario" outlined label="Usuário" placeholder="usuário" hide-details class="mb-3"
              :rules="[() => !!usuario || 'Campo obrigatório']" :error-messages="errorMessages" ref="usuario">
            </v-text-field>

            <v-text-field v-model="senha" outlined :type="isPasswordVisible ? 'text' : 'password'" label="Senha"
              placeholder="············" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[() => !!senha || 'Campo obrigatório']" :error-messages="errorMessages" ref="senha">
            </v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">

              <!-- forgot link -->
              <router-link class="mt-1" :to="{ name: 'esqueciSenha' }">
                Esqueci a senha ?
              </router-link>
            </div>

            <v-btn block color="primary" class="mt-6" @click="login()">
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Novo no sistema ?
          </span>
          <router-link :to="{ name: 'registrar' }">
            Crie a conta para sua empresa
          </router-link>
        </v-card-text>

        <!-- divider -->
        <!--   <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)">

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)

    return {
      isPasswordVisible,
      usuario: "",
      senha: "",
      formHasErrors: false,
      errorMessages: '',
      mensagemErroTrue: false,
      mensagemErro: '',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    login: function () {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) {
          this.formHasErrors = true
        }

        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.$http.post("login", { usuario: this.usuario, senha: this.senha })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              localStorage.setItem("login", true);
              localStorage.setItem("email", res.data.dados.email);
              localStorage.setItem("id", res.data.dados.id);
              localStorage.setItem("idEmpresa", res.data.dados.idEmpresa);
              localStorage.setItem("nome", res.data.dados.nome);
              localStorage.setItem("tipo", res.data.dados.tipo);
              localStorage.setItem("token", res.data.dados.token);
              localStorage.setItem("usuario", res.data.dados.usuario);

              this.$router.push('dashboard')

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.mensagemErroTrue = true
              this.mensagemErro = res.data.dados
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    submit() {

    },
  },

  computed: {
    form() {
      return {
        usuario: this.usuario,
        senha: this.senha,
      }
    },
  },

  watch: {
    name() {
      this.errorMessages = ''
    },
  },

  beforeMount() {
    var logado = localStorage.getItem("login")
    if (logado != undefined && logado == "true") {
      this.$router.push('dashboard')
    }
  }


}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
